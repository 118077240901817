@use "../../../theme/vars.scss";

.logo {
  height: 70px;
  width: 70px;

  img {
    @media all and (-ms-high-contrast: none) {
      width: inherit;
      height: inherit;
    }
  }
}

.logoSquare {
  display: flex;
  flex-direction: row;
  @media (max-width: vars.$screen-xs-max) {
    flex-direction: column;
  }
}

.logoSquareCustomization {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: vars.$screen-xs-max) {
    flex-direction: column;
    margin-left: 0px;
    align-items: flex-start;
  }

  //Styling for BG Shape & Color Parent Ctr
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  //Styling for BG Shape Ctr
  .logoSquareBgShape {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }
  .label {
    font-weight: bold;
  }

  .actions {
    margin-left: 30px;
    margin-top: 0px;
    > button:not(:first-child) {
      margin-left: 10px;
    }

    @media (max-width: vars.$screen-xs-max) {
      margin-left: 0px;
      margin-top: 30px;
    }
  }
}

.uploadForm label {
  white-space: normal;
}

.bannerImage {
  width: 720px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #fff;
  overflow: hidden;
  background-image: linear-gradient(60deg, var(--color-primary) 0%, transparent 300%);

  source {
    object-fit: cover;
  }
  .customBanner {
    filter: brightness(60%);
  }
  .exampleText {
    position: absolute;
    user-select: none;
    text-align: center;
    padding: 1rem;
  }
}

.contactEmail {
  max-width: 600px;
}

.sortableList {
  padding: 22px 0;
}

.sortableElement {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  margin: 0px -15px;

  &:hover {
    background-color: var(--color-gray-lighter);
  }

  > i {
    padding: 5px;
  }

  .handle {
    cursor: ns-resize;
  }

  .formItemAvatar {
    margin-left: 20px;
  }

  .itemLabel {
    flex-grow: 1;
    word-break: break-all;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
  }
}

.draggingElement {
  pointer-events: auto !important;
}

.formItemAvatar {
  min-width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 16px;

  .formItemIcon {
    margin-top: 7px;
  }
}

.secondaryLabel {
  color: var(--color-gray);
  font-size: 85%;
  margin-top: 2px;
}
