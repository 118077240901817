@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

$break-point: 888px;
$max-width: 1222px;

.accountContainer {
  max-width: $max-width;
  display: flex;
  margin: 17px auto 0 auto;
  background-color: white;

  .accountInfo {
    min-width: 324px;
    max-width: 324px;
  }

  .accountResources {
    overflow: hidden;
    flex-grow: 1;
    padding: 20px;
  }

  @media (max-width: $max-width) {
    margin: 0;
  }

  @media (max-width: $break-point) {
    flex-direction: column;
    margin: 0;

    .accountInfo {
      max-width: unset;
      min-width: unset;
    }

    .accountResources {
      padding: 10px;
    }
  }
}

/* Account info */

.accountInfoContainer {
  height: 100%;
  background: var(--color-banner);
  background: linear-gradient(176deg, black -130%, var(--color-banner) 45%, transparent 200%);
  padding: 22px 30px 40px 30px;
  word-break: break-word;

  @media (max-width: $break-point) {
    padding: 20px 25px;
  }

  &,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: var(--color-primary-contrast);
  }

  h3 {
    margin: 0;
  }

  .avatar {
    float: right;
    margin-left: 10px;
    margin-right: -10px;
  }

  .settings {
    margin-left: 10px;
    margin-right: -10px;
  }

  .description {
    a {
      text-decoration: underline;
      &:hover {
        text-shadow: 1px 0 0;
      }
    }
  }

  .members {
    margin-top: 12px;
    margin-right: -4px;
    margin-bottom: -11px;
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-right: 4px;
      margin-bottom: 11px;
    }
  }
  .addMemberLink:focus-visible {
    // we want to highlight the div inside the anchor tag
    outline: none;
    .addMember {
      position: relative;
    }
    .addMember::after {
      // we also want a square outline and not a circular one to match the circle beside it
      content: "";
      width: 29px;
      height: 29px;
      position: absolute;
      top: -1px;
      left: -1px;
      outline: vars.$focus-border;
    }
  }

  .addMember {
    border: 2px solid var(--color-primary-contrast);
    font-family: "Lucida Console", Monaco, monospace;
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: inline-block;
    font-size: 20px;
    line-height: 25px;
    color: var(--color-primary-contrast);
    text-align: center;
    &:hover {
      border-width: 3px;
      line-height: 23px;
    }
  }
}

/* Tabs */

.tabContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-gray-lighter);

  @media (max-width: 1070px) {
    flex-direction: column-reverse;
    .addItemButton {
      margin-bottom: 18px;
    }
  }

  .tabs {
    display: flex;
    flex-grow: 1;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    .tab {
      cursor: pointer;
      padding: 9px 23px;
      letter-spacing: 0.7px;
      margin-right: 5px;
    }

    .tab:focus,
    .tab:hover {
      text-decoration: none;
    }
    .tab:focus-visible {
      // just an outline isn't entirely visible
      border: vars.$focus-border;
      outline: none;
    }

    .activeTab {
      border-bottom: 2px solid var(--color-secondary);
    }

    .counter {
      margin-left: 10px;
      margin-right: -5px;
      color: var(--color-gray);
      font-size: 0.67rem;
    }
  }
}

/* AddItemButton */

.addItemButton {
  display: inline-block;
  .mainButtonWrapper {
    display: flex;
    flex-wrap: nowrap;
  }

  > div:first-of-type {
    padding-top: 1px;
    padding-bottom: 1px;
    white-space: nowrap;
  }
  .mainButton {
    border-radius: 4px 0 0 4px;
  }
  .dropdownButton {
    border-radius: 0 4px 4px 0;
    padding: 6px;
    min-width: 35px;
  }
  .popper {
    z-index: zIndexes.$popper-z;
  }
  .dropdown {
    button {
      text-align: left;
      border-radius: 4px;
      width: 100%;
    }
    button:first-of-type {
      margin-bottom: 1px;
    }
  }
}

/* Account resources */

$border-color: #b7b7b7;
$form-background-color: #f8f8f8;

.accountResources {
  .configure:hover {
    text-decoration: underline;
  }
  .configure:focus-visible {
    // makes a slightly prettier outline
    padding-right: 0.75em;
  }

  .center {
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;
  }

  .empty {
    color: var(--color-gray-lighter);
  }

  .title {
    margin-top: 50px;
    text-align: center;
  }

  .form {
    background-color: $form-background-color;
    border-radius: 20px;
    padding: 20px;
  }

  .item {
    border: 1px solid $border-color;
    border-radius: 4px;
  }

  .listItem {
    margin-top: 14px;
    @extend .item;
  }

  .showMore {
    text-align: center;
  }
}

/* Overview */

.masonryWrapper {
  max-width: 100%;
  overflow: hidden;
}

.masonry {
  width: calc(100% + 16px);
}

/* Overview form */

.overviewForm {
  padding: 26px 27px 22px 27px;

  h3 {
    margin-top: 0;
    margin-bottom: 9px;
  }
}

.sortableList {
  padding: 22px 0;
}

.sortableElement {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  margin: 0px -15px;

  &:hover {
    background-color: var(--color-gray-lighter);
  }

  > i {
    padding: 5px;
  }

  .handle {
    cursor: ns-resize;
  }

  .formItemAvatar {
    margin-left: 20px;
  }

  .itemLabel {
    flex-grow: 1;
    word-break: break-all;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
  }
}

.draggingElement {
  pointer-events: auto !important;
  z-index: zIndexes.$popper-z;
}

.inputField {
  margin-bottom: 43px;
}

.formItemAvatar {
  min-width: 30px;
  height: 30px;
  text-align: center;
  margin-right: 16px;

  .formItemIcon {
    margin-top: 7px;
  }
}

.secondaryLabel {
  color: var(--color-gray);
  font-size: 85%;
  margin-top: 2px;
}
