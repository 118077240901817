.redirectLabel {
  flex-direction: column;
  align-self: center;
  margin-left: -1em;
  margin-right: 0.5em;
}

.cursorOverride {
  pointer-events: auto !important;
}
