@use "../../../../theme/vars.scss";

.noselect {
  user-select: none;
}

.ruleRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .enabledDragHandle {
    align-self: center;
    cursor: ns-resize;
  }

  .disabledDraghandle {
    cursor: not-allowed;
  }

  .ruleData {
    display: flex;
    margin-left: 12px;

    .matchContainer {
      margin-left: 1em;
      display: flex;

      .labelWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .match {
        margin-left: 1em;
        font-family: monospace;
      }
    }

    .datasetLink {
      margin-left: 1em;
    }
  }

  .ruleActions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
  }
}
