@use "../../theme/vars.scss";
@use "../../theme/zIndexes.scss";

.container {
  max-width: 100%;
  min-width: 550px;
}
.titleAndServiceSelector {
  display: flex;
}
.serviceSelector {
  min-width: 300px; //Need this much space for our default item in this select box
  padding-left: 10px;
}

.spacer {
  flex-grow: 1;
}

.warningIcon {
  color: var(--color-warning);
  margin-bottom: 1px;
  margin-right: 5px;
}

.yasqe {
  margin-top: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
  :global(.yasqe) :global(.yasqe_buttons) :global(.yasqe_share) {
    display: none;
  }
}
.yasqeLdEditor {
  margin-bottom: 0;
  :global(.resizeWrapper) {
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
  }
}
.yasr {
  margin-top: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;

  :global(.yasr_results) {
    overflow: auto;
  }
}
.readOnlyMetadata {
  .title {
    word-break: break-word;
  }
  .metadataList {
    font-size: 95%;
    flex-grow: 1;
  }
  .serviceLink {
    word-break: break-all;
    word-wrap: break-word;
    width: fit-content;
  }
  .description {
    margin-top: -10px;
  }
  .menu {
    align-self: flex-start;
    margin-right: -6px;
  }
}
.versionRow {
  margin: 6px 20px 0px 20px;
  display: flex;
  align-items: center;
  height: 40px;
  & button:focus-visible {
    outline: vars.$focus-border;
  }
}
.versionBtn {
  &.empty {
    cursor: not-allowed;
    color: #1690c6;
  }
  &:hover,
  &:focus {
    //blue underline is ugly when red 'draft' message is there
    text-decoration: none;
  }
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
}
.versionMenu {
  ul {
    padding-top: 0;
    padding-bottom: 0;
  }
  li {
    padding: 0 8px 0 8px;
  }
}
.draft {
  .versionLabel {
    //not using our regular error color. Want a bit of a softer one
    color: #dd4b39;
  }
  &.readView {
    .versionLabel {
      color: black;
    }
  }
}

.queryVars {
  padding: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  border: 1px solid #d1d1d1;
  border-bottom: 0;

  h4 {
    margin-right: 1rem;
  }
}
.codeSnippet {
  margin-left: auto;
  align-self: right;
}
.queryVarDragHandle {
  cursor: ns-resize;
}
.sortableQueryVarWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 1.75rem;
}
.queryVariable {
  padding-left: 1rem;
  flex-grow: 1;
  .variableInfo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    .warning {
      margin-left: 1rem;
      color: var(--color-warning);
    }
    .info {
      cursor: pointer;
      font-size: 1.2rem;
      margin-left: 1rem;
      color: var(--color-info);
      .infoIconActive {
        display: none;
      }
      &:hover,
      &.moreInfoOpen {
        .infoIconActive {
          display: block;
        }
        .infoIcon {
          display: none;
        }
      }
    }
  }

  dt {
    font-weight: bold;
  }
  .variableAction {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
}
.apiUrlWrapper {
  margin: 0.25rem 0 0.5rem 0;
  display: flex;
  align-items: center;
}
.moreInfoPopup {
  dt {
    font-weight: bold;
  }
  padding: 0.5rem;
  min-width: 300px;
}

.apiRunLinkWrapper {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  .codeSnippetLink {
    $shadowColor: rgba(184, 184, 184, 0.479);
    position: relative;
    font-family: monospace;
    font-weight: normal;
    font-size: 0.8em;
    background-color: var(--color-gray-lighter);
    box-shadow:
      inset 2px 0 4px $shadowColor,
      inset -2px 0 4px $shadowColor;
    padding: 0.25em 0.75em;
    word-break: break-all;
    line-height: 1.75em;
    border-left: 3px solid var(--color-primary);
    border-radius: 2px;
    transition:
      color 400ms,
      background-color 400ms;
  }
  .copyToClipboard {
    margin-left: 0.5rem;
    &:active {
      transform: translate(-1px, 1px);
    }
  }
}

.popper {
  z-index: zIndexes.$popper-z;
}
.loadingBar {
  position: absolute;
  left: 0;
  right: 0;
}

.copyQueryDialog {
  .accounts {
    display: flex;
    flex-wrap: wrap;
  }

  .account {
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    .accountName {
      text-align: center;
      word-break: break-all;
      word-wrap: break-word;
      max-width: 80px;
    }
  }
}

.tabs {
  border-bottom: 1px solid var(--color-gray-light);
  height: 38px;
  min-height: 38px;
}

.tab {
  min-height: 38px;
  min-width: 110px;

  &:hover {
    background-color: var(--color-gray-lighter);
  }
}

.moveAccounts {
  display: flex;
  flex-wrap: wrap;
}

.moveAccount {
  cursor: pointer;
  opacity: 0.8;
  background: 0;
  border: 0;
  &:focus-visible {
    outline: 1px var(--color-secondary) dashed;
  }

  &:hover {
    opacity: 1;
  }

  .moveAccountName {
    display: block;
    text-align: center;
    word-break: break-all;
    word-wrap: break-word;
    max-width: 80px;
  }
}

.dependents {
  > h5 {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
.dependentsList {
  gap: 6px;
  flex-direction: column;
  word-break: break-word;
}

.codeMirror {
  margin-top: 0;
  border: 1px solid #d1d1d1;
  :global(.cm-editor) {
    max-height: 500px;
  }
}
.cmError {
  text-decoration: underline wavy var(--color-error);
}
.frameTitle {
  margin-top: 0;
  margin-bottom: 0;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}

.newEditor {
  position: relative;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
}
.editorElement {
  margin-top: 0;
  margin-bottom: 0;
}
.bar {
  justify-content: flex-end;
}
.executeQueryButton {
  padding: 6px 10px;
  margin: 6px;
  min-width: unset;
  .playIcon {
    height: 1.5rem;
  }
}

.resultsElement {
  margin-top: 0;
  margin-bottom: 1rem;
  min-height: 300px;
}

.resultsNetwork {
  height: 400px;
}

.resultsGeo {
  height: 600px;
}

.resultsTimeline {
  height: 600px;
}

// "Hacks" the dragHandle when it goes outside of the hover
.dragging {
  cursor: ns-resize;
  > * {
    pointer-events: none;
  }
}

.dragContainer {
  position: relative;
}
.resizableEditor {
  height: inherit;
  :global(.cm-editor) {
    height: inherit;
  }
}

.queryOwnerName {
  overflow-wrap: anywhere;
}
.autoResizeContainer {
  display: flex;
  justify-content: center;
}

.autoResize {
  position: absolute;
  bottom: 10px;

  .autoResizeNoBg {
    background-color: unset;
  }
  .autoResizeWhiteBg {
    background-color: #ffffff;
    opacity: 0.8;
  }
}
.resultsResponse {
  max-height: 600px;
}
.largeResultsContainer {
  height: 300px;
  position: relative;
}
