@use "../../theme/vars.scss";

div.box {
  cursor: auto;
  padding: 7px 10px;
  border: 1px solid transparent;
  &.dragActive {
    border: 1px solid var(--color-gray);
  }
}

.main {
  display: flex;
}

.title {
  margin: 0;
  display: inline-block; //otherwise border will span complete width
}

.wrap {
  display: flex;
  flex-wrap: wrap;

  flex-grow: 1;
}
.colDescription {
  flex-grow: 1;
  padding: 8px 10px;
}
.progressBar {
  width: 95%;
  height: 6px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.description {
  margin-top: 9px;
  white-space: nowrap;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  max-width: 100%;
  margin-bottom: 0; //override browser default. padding is done by parent already, and having browser-defaulted padding
  //will messup vertical central alignment
}
.button {
  margin-top: 0px;
  margin-left: 5px;
}
.buttonIcon {
  margin-right: 7px;
}
.remove {
  margin-left: 15px;
}
.colIcons {
  font-size: 130%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  padding: 8px 5px;

  & > i {
    padding: 5px;
    cursor: pointer;

    &:hover {
      color: var(--color-warning);
    }
  }
}
div.buttons {
  margin-right: 0px;
}
.versions {
  margin: 0;
  &:not(:empty) {
    padding: 10px;
  }

  & > .version:nth-child(odd) {
    background-color: #f5f5f5;
  }
}
.version {
  display: flex;
  align-items: center;
}
.versionColumn {
  flex-grow: 1;
  .downloadIcon {
    &:hover {
      color: var(--color-warning);
    }
  }
}
